import { useState } from "react";
import { Grid, Paper, Typography, Box, Popover, Divider } from "@mui/material";
import { StyledActivityRow } from "./styled";
import ChipAtom from "@atoms/Chip";
import IActivityRow from "./interface";
import MultiButtonMolecule from "@molecules/MultiButtonMolecule";
import Tooltip from "@mui/material/Tooltip";
import DatePrintAtom from "@atoms/DatePrint";
import { useSelector } from "react-redux";
import OnlineOfflineMemberIconMolecule from "@molecules/OnlineOfflineMemberIcon";
import { useUser } from "@hooks";
import { t } from "@lingui/macro";
import { Link } from "react-router-dom";
import DataBalloonAtom from "@atoms/DataBalloon";
import { ActivityModel } from "@models/ActivityModel";

const InspectionActivityRowMolecule = ({
  activity,
  onActionSelected,
  hideMultButton,
}: IActivityRow) => {
  const auth = useUser();

  // Check if user is online
  const webRTCMembersOnline = useSelector(
    (state: any) => state.chat.webrtcUsersOnline,
  );
  const userIsOnline = webRTCMembersOnline.find(
    (member: any) => member.id === activity.getUser()?.id,
  );

  const users = useSelector((state: any) => state.filters.filters.users);

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [anchorElNotifications, setAnchorElNotifications] =
    useState<HTMLButtonElement | null>(null);
  const [anchorElProjectLabel, setAnchorElProjectLabel] =
    useState<HTMLButtonElement | null>(null);

  const actionSelected = (item: any) => {
    if (onActionSelected) {
      onActionSelected(item);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseNotifications = () => {
    setAnchorElNotifications(null);
  };

  const handleCloseProjectLabel = () => {
    setAnchorElProjectLabel(null);
  };

  const getInspectorsNames = (activity: ActivityModel) => {
    const inspectors = new Set();

    activity.rel_activities.forEach((relActivity: ActivityModel) => {
      if (relActivity.user_id) {
        const full_name = users.find(
          (user: any) => user.id === relActivity.user_id,
        )?.full_name;
        if (full_name) inspectors.add(full_name);
      }
    });

    if (activity.inspection?.user?.full_name) {
      inspectors.add(activity.inspection.user.full_name);
    }

    return Array.from(inspectors).join(", ");
  };

  const openNotificationsPopover = (e: any) => {
    setAnchorElNotifications(e.currentTarget);
  };

  const openProjectLabelPopover = (e: any) => {
    setAnchorElProjectLabel(e.currentTarget);
  };

  const open = Boolean(anchorEl);
  const openNotifications = Boolean(anchorElNotifications);
  const openProjectLabel = Boolean(anchorElProjectLabel);

  return (
    <>
      <Paper sx={{ border: "0px" }} elevation={0} variant="outlined">
        <StyledActivityRow>
          <Grid container spacing={1}>
            <Grid
              item
              sx={{
                textAlign: "left",
                display: "flex",
                alignItems: "center",
                gap: "2px",
              }}
              md={2}
              xs={12}
            >
              <DataBalloonAtom
                title={t`Total activities`}
                variant="chip"
                severity={"warning"}
                data={activity.totalRelActivities()}
              />
              <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
              >
                <Box p={2}>
                  <ul className="ul-no-margins">
                    {activity.rel_activities.map(
                      (relActivity: any, index: number) => {
                        return (
                          <li key={index}>
                            <Box
                              mb={1}
                              sx={{
                                fontSize: "13px",
                              }}
                            >
                              <Box
                                sx={{
                                  fontWeight:
                                    relActivity.id === activity.id
                                      ? "bold"
                                      : "",
                                }}
                              >
                                <DatePrintAtom format="DD/MM/yyyy">
                                  {relActivity.date}
                                </DatePrintAtom>
                              </Box>
                              <Box></Box>
                            </Box>
                          </li>
                        );
                      },
                    )}
                  </ul>
                </Box>
              </Popover>
              <Box
                onClick={(e: any) => setAnchorEl(e.currentTarget)}
                className="mouse-hover"
              >
                <Box className="date">
                  <Typography>
                    {activity.date ? (
                      <DatePrintAtom format="DD/MM/yyyy">
                        {activity.date}
                      </DatePrintAtom>
                    ) : (
                      "--"
                    )}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "5px",
                  }}
                >
                  <Box>
                    <Typography>
                      {activity.start_time ? activity.startTime() : "--"}
                    </Typography>
                  </Box>
                  -
                  <Typography>
                    {activity.end_time ? activity.endTime() : "--"}
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid
              sx={{
                textAlign: "left",
                alignItems: "center",
                display: "flex",
                gap: "1px",
                paddingRight: "5px",
              }}
              item
              md={2}
              xs={12}
            >
              {auth.user.id !== activity.getUser()?.id && (
                <OnlineOfflineMemberIconMolecule
                  memberId={activity.getUser().id}
                />
              )}
              {activity.inspection?.user ? (
                <Tooltip title={getInspectorsNames(activity)} placement="top">
                  <Box>{`${activity.inspection?.user?.full_name}`}</Box>
                </Tooltip>
              ) : (
                "--"
              )}
            </Grid>
            <Grid
              item
              md={2}
              xs={12}
              sx={{ alignItems: "center", display: "flex" }}
            >
              <Box className="fullwidth">
                <Tooltip title={t`Client`} placement="top">
                  <Box>{activity.inspection.job.client.name}</Box>
                </Tooltip>
                <Divider />
                <Tooltip
                  title={
                    activity.inspection.subvendor
                      ? `Subvendor: ${activity.inspection.subvendor.name}`
                      : t`Vendor`
                  }
                  placement="bottom"
                >
                  <Box>
                    <Box sx={{ display: "inline-block" }}>
                      {activity.inspection.vendor.name}
                    </Box>
                    {activity.inspection.subvendor && (
                      <Box
                        sx={{
                          width: "7px",
                          margin: "0 5px",
                          borderRadius: "7px",
                          display: "inline-block",
                          height: "7px",
                          backgroundColor: "#2058e8e3",
                        }}
                      ></Box>
                    )}
                  </Box>
                </Tooltip>
              </Box>
            </Grid>
            <Grid
              item
              md={1}
              xs={12}
              sx={{ alignItems: "center", display: "flex" }}
            >
              {activity.status && (
                <ChipAtom
                  label={activity.report_status.name}
                  color={activity.report_status.class}
                />
              )}
            </Grid>
            <Grid
              sx={{
                alignItems: "center",
                display: "flex",
                gap: "5px",
              }}
              item
              md={3}
              xs={12}
            >
              <Popover
                open={openNotifications}
                anchorEl={anchorElNotifications}
                onClose={handleCloseNotifications}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
              >
                <Box p={2}>
                  <ul className="ul-no-margins">
                    {activity
                      .notifications()
                      .map((notification: any, index: number) => {
                        return (
                          <li key={index}>
                            <Box
                              sx={{
                                fontSize: "13px",
                              }}
                            >
                              {notification.name}
                            </Box>
                          </li>
                        );
                      })}
                  </ul>
                </Box>
              </Popover>
              {activity.inspection.pos?.name &&
                activity.notifications("string", 30) && (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      width: "200px",
                    }}
                  >
                    <Tooltip title={t`PO`} placement="top">
                      <Box>
                        {activity.inspection.pos?.name}
                        <Divider />
                      </Box>
                    </Tooltip>
                    <Tooltip title={t`Notification`} placement="top">
                      <Box
                        onClick={openNotificationsPopover}
                        className="mouse-hover"
                      >
                        {activity.notifications("string", 30)}
                      </Box>
                    </Tooltip>
                  </Box>
                )}
              <Popover
                open={openProjectLabel}
                anchorEl={anchorElProjectLabel}
                onClose={handleCloseProjectLabel}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
              >
                <Box p={2} sx={{ fontSize: "13px" }}>
                  <ul>
                    <li>Job label: {activity.inspection.job.label}</li>
                    <li>Project label: {activity.inspection.project_label}</li>
                  </ul>
                </Box>
              </Popover>
              <Box
                onClick={openProjectLabelPopover}
                className="mouse-hover"
                sx={{
                  marginLeft: "20px",
                  width: "300px",
                }}
              >
                {activity.inspection.project_label
                  ? activity.inspection.project_label.length > 60
                    ? activity.inspection.project_label.substring(0, 60) + "..."
                    : activity.inspection.project_label
                  : ""}
              </Box>
            </Grid>
            <Grid sx={{ textAlign: "right" }} item md={2} xs={12}>
              {!hideMultButton && (
                <MultiButtonMolecule
                  onSelected={actionSelected}
                  options={[
                    {
                      label: "Details",
                      id: 0,
                      data: {
                        activityId: activity.id,
                        inspectionId: activity.inspection.id,
                      },
                    },
                    {
                      label: (
                        <Link to={`/jobs?id=${activity.inspection.job_id}`}>
                          Go to job
                        </Link>
                      ),
                      id: 1,
                      data: { activityId: activity.id },
                    },
                    {
                      label: "Open chat",
                      id: 2,
                      data: {
                        activityId: activity.id,
                        activity,
                      },
                      disabled:
                        auth.user.id !== activity.getUser().id ? false : true,
                    },
                    {
                      label: "Video call",
                      id: 3,
                      data: {
                        activityId: activity.id,
                        activity,
                      },
                      disabled:
                        !userIsOnline || auth.user.id === activity.getUser().id
                          ? true
                          : false,
                    },
                    {
                      label: "Copy",
                      id: 4,
                      data: { activityId: activity.id },
                    },
                    {
                      label: "Delete",
                      id: 5,
                      data: { activityId: activity.id },
                      disabled:
                        auth.user.id !== activity.getUser().id ? false : true,
                    },
                  ]}
                />
              )}
            </Grid>
          </Grid>
        </StyledActivityRow>
      </Paper>
    </>
  );
};

export default InspectionActivityRowMolecule;
