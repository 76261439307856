import * as Yup from "yup";
import { t } from "@lingui/macro";

const createSchema = Yup.object().shape({
  // status_id: Yup.number(),
  user_id: Yup.number()
    .typeError(t`Required`)
    .required(t`Required`)
    .nullable(),
  category_id: Yup.number()
    .typeError(t`Required`)
    .required(t`Required`)
    .nullable(),
  activity_category_id: Yup.number()
    .typeError(t`Required`)
    .required(t`Required`)
    .min(1, "Required"),
  partner_id: Yup.number()
    .notRequired()
    .nullable()
    .when("category_id", {
      is: 5, // constant?
      then: Yup.number()
        .typeError(t`Required`)
        .required()
        .min(1, "Required"),
    }),
  country_id: Yup.number().nullable() /*.typeError(t`Required`).required()*/,
  new_vendor: Yup.boolean(),
  new_subvendor: Yup.boolean(),
  new_po: Yup.boolean(),
  po_name: Yup.string()
    .notRequired()
    .when("new_po", {
      is: true,
      then: Yup.string().min(1, "Required").required(),
    }),
  vendor_id: Yup.mixed()
    .notRequired()
    .when("new_vendor", {
      is: false,
      then: Yup.number()
        .typeError(t`Required`)
        .required()
        .min(1, "Required"),
    }),
  vendor_name: Yup.mixed()
    .nullable()
    .notRequired()
    .when("new_vendor", {
      is: true,
      then: Yup.string().min(1, "Required").required(),
    }),
  vendor_email: Yup.mixed()
    .nullable()
    .notRequired()
    .when("new_vendor", {
      is: true,
      then: Yup.string().email().min(1, "Required").required(),
    }),
  vendor_country: Yup.mixed()
    .nullable()
    .notRequired()
    .when("new_vendor", {
      is: true,
      then: Yup.number().min(1, "Required").required(),
    }),
  vendor_division: Yup.mixed().nullable().notRequired().when("new_vendor", {
    is: true,
    then: Yup.mixed().nullable().notRequired(), // Yup.string().min(1, 'Required').required()
  }),
  subvendor_name: Yup.mixed()
    .nullable()
    .notRequired()
    .when("new_subvendor", {
      is: true,
      then: Yup.string().min(1, "Required").required(),
    }),
  subvendor_email: Yup.mixed()
    .nullable()
    .notRequired()
    .when("new_subvendor", {
      is: true,
      then: Yup.string().email().min(1, "Required").required(),
    }),
  subvendor_country: Yup.mixed()
    .nullable()
    .notRequired()
    .when("new_subvendor", {
      is: true,
      then: Yup.number().min(1, "Required").required(),
    }),
  subvendor_division: Yup.mixed()
    .nullable()
    .notRequired()
    .when("new_subvendor", {
      is: true,
      then: Yup.mixed().nullable().notRequired(), // Yup.string().min(1, 'Required').required()
    }),
  subcontracting: Yup.boolean().nullable(),
  subcontractor_id: Yup.number()
    .typeError(t`Required`)
    .nullable()
    .notRequired()
    .when("subcontracting", {
      is: true,
      then: Yup.number().typeError(t`Required`),
    }),
  labels: Yup.array().of(Yup.mixed()).nullable(),
  po: Yup.array().of(Yup.mixed()).nullable(),
  street: Yup.string() /*.required()*/,
  different_user_invoice: Yup.boolean().nullable(),
  user_invoice_id: Yup.number()
    .typeError(t`Required`)
    .nullable()
    .notRequired()
    .when("different_user_invoice", {
      is: true,
      then: Yup.number()
        .typeError(t`Required`)
        .required("Required"),
    }),
});

export default createSchema;
