import React, {
  useState,
  Fragment,
  useMemo,
  useCallback,
  useEffect,
} from "react";
import { Grid, Button, Typography, Paper, Box } from "@mui/material";
import { Trans, t } from "@lingui/macro";
import ContainerAtom from "@atoms/Container";
import LayoutBase from "@layouts/base";
import DownloadIcon from "@mui/icons-material/Download";
import BoxTotalAtom from "@atoms/BoxTotal";
import { PageHeader, StyledGridItem, StyledHeaderContainer } from "./styled";
import {
  filterSelector,
  filtersTypeSelector,
} from "@stores/selectors/filtersSelector";
import AddIcon from "@mui/icons-material/Add";
import Modal from "@molecules/Modal";
import NewPersonOrganism from "@organisms/PersonCreate";
import PaginatedListMolecule from "@molecules/PaginatedList";
import { IMBOption } from "@molecules/MultiButtonMolecule/interface";
import PeopleDetailOrganism from "@organisms/PeopleDetail";
import { useQuery } from "react-query";
import PeopleService from "@services/PeopleService";
import { toast } from "react-toastify";
import type { IPeopleFilterProps } from "@organisms/PeopleFilter/interface";
import { peopleFilterInitialValues } from "./data";
import PeopleFilterOrganism from "@organisms/PeopleFilter";
import PeopleRowMolecule from "@molecules/PeopleRow";
import { PeopleModel } from "@models/PeopleModel";
import Stack from "@mui/material/Stack";
import { useConfirm } from "material-ui-confirm";
import SelectedFilterMolecule from "@molecules/SelectedFilter";
import type { ISelectedFilterItem } from "@molecules/SelectedFilter/interface";
import { useAppSelector } from "@stores/hooks";
import isEqual from "lodash/isEqual";
import { deleteItem } from "@stores/reducers/filtersReducer";
import { useAppDispatch } from "@stores/hooks";
import SortUI from "@molecules/SortUI";
import DrawerMolecule from "@molecules/Drawer";
import AttributesService from "@services/AttributesService";
import ButtonAtom from "@atoms/Button";
import { useUser } from "@hooks";
import { display } from "@mui/system";

const ActivitiesPage = () => {
  const dispatch = useAppDispatch();
  const user = useUser();
  const [attributes, setAttributes] = useState<any>([]);
  const [filter, setFilter] = useState<IPeopleFilterProps>(
    peopleFilterInitialValues,
  );
  const [maskFilter, setMaskedFilter] = useState<IPeopleFilterProps>(
    peopleFilterInitialValues,
  );
  const [modalOpen, setModalOpen] = useState(false);
  const [drawerDetailID, setDrawerDetailID] = useState<any>({});
  const [exportingInspectors, setExportingInspectors] = useState(false);
  const [state, setState] = useState({ right: false });
  const [totals, setTotals] = useState<any>({});
  const [newRow, setNewRows] = useState<PeopleModel | undefined>(undefined);
  const [toDelete, setToDeleteFromList] = useState<number | undefined>(
    undefined,
  );
  const [updatedItem, setUpdated] = useState<PeopleModel | undefined>(
    undefined,
  );
  const isNameChanged = !isEqual(
    filter.search,
    peopleFilterInitialValues.search,
  );
  const isCityChanged = !isEqual(filter.city, peopleFilterInitialValues.city);
  const countriesData = useAppSelector(filterSelector("countries"));
  const typesData = useAppSelector(filtersTypeSelector("inspections"));
  const isCountryChanged = !isEqual(
    filter.country_id,
    peopleFilterInitialValues.country_id,
  );
  const isTypeChanged = !isEqual(
    filter.type_id,
    peopleFilterInitialValues.type_id,
  );
  const isInactiveChanged = !isEqual(
    filter.inactive,
    peopleFilterInitialValues.inactive,
  );
  const confirm = useConfirm();

  const toggleDrawer =
    (anchor: any, open: boolean) => (event: KeyboardEvent | MouseEvent) => {
      if (
        event &&
        event.type === "keydown" &&
        ((event as KeyboardEvent).key === "Tab" ||
          (event as KeyboardEvent).key === "Shift")
      ) {
        return;
      }
      setState({ ...state, [anchor]: open });
    };

  useQuery(
    "activities-total",
    () =>
      PeopleService.getTotals().then((res: any) => {
        setTotals(res.getData());
      }),
    {
      refetchOnWindowFocus: false,
      cacheTime: 0,
      refetchOnMount: false,
    },
  );

  const { data, isLoading } = useQuery(
    `attributes-users`,
    () => AttributesService.get("", { model_type: "users" }),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    },
  );

  useEffect(() => {
    if (!isLoading && data) {
      // @ts-ignore
      setAttributes(data.data);
    }
  }, [data, isLoading]);

  const exportInspectors = () => {
    setExportingInspectors(true);
    const toastWarningID = toast(
      "Downloading... please wait it could take a few seconds",
      {
        type: "warning",
        autoClose: false,
      },
    );
    PeopleService.exportInspectors().then((res: any) => {
      setExportingInspectors(false);
      toast.dismiss(toastWarningID);
      toast(res.getMsgString(), {
        type: res.hasErrors() ? "error" : "success",
      });
    });
  };

  const handleActionSelect = (param: IMBOption) => {
    if (param.label === "Details") {
      setDrawerDetailID(param.data.personId);
      setState({ ...state, right: true });
    } else if (param.label === "Delete") {
      confirm({
        title: "Are you sure you want to delete this element?",
        description: "The action is irreversible!",
        cancellationText: "Go back",
        confirmationText: "Delete",
      }).then(() => {
        PeopleService.delete(param.data.personId).then((res: any) => {
          toast(res.getMsgString(), {
            type: res.hasErrors() ? "error" : "success",
          });
          if (!res.hasErrors()) {
            setToDeleteFromList(param.data.personId);
            // Remove from store
            dispatch(
              deleteItem({
                what: "users",
                id: param.data.personId,
              }),
            );
          }
        });
      });
    }
  };

  const countries = useMemo(() => {
    return countriesData.map((country) => ({
      ...country,
      value: country.id,
      label: country.name,
    }));
  }, [countriesData]);

  const types = useMemo(() => {
    return typesData.map((type) => ({
      ...type,
      value: type.id,
      label: type.name,
    }));
  }, [typesData]);

  const filterSelections = useMemo((): ISelectedFilterItem[] => {
    const output = [
      {
        label: `${t`Name`}: ${filter.search}`,
        value: "search",
        isChanged: isNameChanged,
      },
      {
        label: `${t`Country`}: ${
          (
            countries.find(
              (country) => country.id === Number(filter.country_id),
            ) || { name: "" }
          ).name
        }`,
        value: "country_id",
        isChanged: isCountryChanged,
      },
      {
        label: `${t`City`}: ${filter.city}`,
        value: "city",
        isChanged: isCityChanged,
      },
      {
        label: `${t`Source`}: ${
          (
            types.find((type: any) => type.id === Number(filter.type_id)) || {
              name: "",
            }
          ).name
        }`,
        value: "type_id",
        isChanged: isTypeChanged,
      },
      {
        label: `${t`Inactive`}: ${filter.inactive ? t`inactive` : t`active`}`,
        value: "inactive",
        isChanged: isInactiveChanged,
      },
    ];
    Object.keys(filter.attributes).forEach((attributeKey: any) => {
      if (filter.attributes[attributeKey]) {
        let attributeLabel, valueLabel;
        const IDValue = filter.attributes[attributeKey];
        attributes.forEach((group: any) => {
          const children = group.children.length
            ? group.children
            : group.attributes;
          children.forEach((childGroup: any) => {
            if (childGroup.attributes) {
              childGroup.attributes.forEach((attribute: any) => {
                if (attribute.id === Number(attributeKey)) {
                  attributeLabel =
                    attribute.label.charAt(0).toUpperCase() +
                    attribute.label.slice(1);
                  if (attribute.type.alias === "select") {
                    attribute.options.forEach((option: any) => {
                      if (Number(option.value) === Number(IDValue)) {
                        valueLabel = option.label;
                      }
                    });
                  } else if (attribute.type.alias === "boolean") {
                    valueLabel = "yes";
                  }
                }
              });
            } else {
              if (Number(childGroup.id) === Number(attributeKey)) {
                const output: Array<string> = [];
                attributeLabel =
                  childGroup.label.charAt(0).toUpperCase() +
                  childGroup.label.slice(1);
                childGroup.options.forEach((option: any) => {
                  if (IDValue.includes(option.value)) {
                    output.push(option.label);
                  }
                });
                valueLabel = output.join(", ");
              }
            }
          });
        });
        output.push({
          label: `${attributeLabel}: ${valueLabel ? valueLabel : t`0`}`,
          value: attributeKey,
          isChanged: true,
        });
      }
    });
    return output;
  }, [
    filter,
    countries,
    types,
    isNameChanged,
    isCityChanged,
    isCountryChanged,
    isTypeChanged,
    isInactiveChanged,
  ]);

  const isNumeric = (number: any) => {
    return !isNaN(parseFloat(number)) && !isNaN(+number);
  };

  const handleDeleteFilter = useCallback((key: string | "all") => {
    if (isNumeric(key)) {
      setFilter((prevState) => {
        return processFilter(
          {
            ...prevState,
            ...{
              attributes: {
                ...prevState.attributes,
                ...{ [key]: null },
              },
            },
          },
          false,
        );
      });
      return;
    }
    switch (key) {
      case "all":
        setFilter((prevState) => {
          return processFilter(peopleFilterInitialValues);
        });
        break;

      default:
        setFilter((prevState) => {
          return processFilter(
            {
              ...prevState,
              ...{
                [key]:
                  peopleFilterInitialValues[key as keyof IPeopleFilterProps],
              },
            },
            false,
          );
        });
    }
  }, []);

  const processFilter = (data: IPeopleFilterProps, setFilterNow = true) => {
    const maskedFilter = { ...data };
    maskedFilter.attributes = [];
    Object.keys(data.attributes).forEach((attributeId: any) => {
      if (
        (!Array.isArray(data.attributes[attributeId]) &&
          data.attributes[attributeId]) ||
        (Array.isArray(data.attributes[attributeId]) &&
          data.attributes[attributeId].length)
      )
        maskedFilter.attributes.push({
          attribute_id: Number(attributeId),
          value: data.attributes[attributeId],
        });
    });
    if (setFilterNow) {
      setFilter(data);
    }
    setMaskedFilter(maskedFilter);
    return data;
  };

  return (
    <>
      <Fragment key={"right"}>
        <DrawerMolecule
          open={state.right}
          onClose={toggleDrawer("right", false)}
        >
          <PeopleDetailOrganism onUpdate={setUpdated} id={drawerDetailID} />
        </DrawerMolecule>
      </Fragment>
      <LayoutBase>
        <ContainerAtom>
          <PageHeader>
            <Typography variant="h1">
              <Trans>Inspectors</Trans>
            </Typography>
            <Button onClick={() => setModalOpen(true)} variant="outlined">
              <AddIcon></AddIcon> <Trans>New inspector</Trans>
            </Button>
          </PageHeader>
          <Modal open={modalOpen} set_open={setModalOpen}>
            <Typography variant={"h2"}>Create inspector</Typography>
            <Paper elevation={0}>
              <NewPersonOrganism
                onNewPerson={(person: PeopleModel) => setNewRows(person)}
              />
            </Paper>
          </Modal>
          <Grid my={2} container spacing={1}>
            {Object.keys(totals).map((totalKey, index) => {
              return (
                <Grid key={index} item md={totalKey !== "total" ? 4 : 4} xs={6}>
                  <BoxTotalAtom
                    totalInteger={totals[totalKey]}
                    label={
                      totalKey === "active" ? (
                        <Trans>Active resources</Trans>
                      ) : totalKey === "all" ? (
                        <Trans>All</Trans>
                      ) : totalKey === "expired_certifications" ? (
                        <Trans>With expiring certificates</Trans>
                      ) : (
                        totalKey
                      )
                    }
                  />
                </Grid>
              );
            })}
          </Grid>
          <Stack mb={2} direction="row" spacing={2} alignItems="center">
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexWrap: "wrap",
                  gap: "5px",
                }}
              >
                <SelectedFilterMolecule
                  items={filterSelections}
                  handleDelete={handleDeleteFilter}
                />
              </Box>
              {user.can("users.export") && (
                <Box>
                  <ButtonAtom
                    loading={exportingInspectors}
                    onClick={() => exportInspectors()}
                    variant="outlined"
                    label={
                      <React.Fragment>
                        <DownloadIcon />
                        &nbsp;<Trans>Export inspectors</Trans>
                      </React.Fragment>
                    }
                  />
                </Box>
              )}
            </Box>
          </Stack>
          <Grid container spacing={1.5}>
            <Grid
              item
              md={3}
              xs={12}
              sx={{
                position: "sticky",
                top: "50px",
                alignSelf: "flex-start",
              }}
            >
              {attributes.length ? (
                <PeopleFilterOrganism
                  attributes={attributes}
                  filter={filter}
                  setFilter={processFilter}
                />
              ) : (
                <></>
              )}
            </Grid>
            <Grid item md={9} xs={12}>
              {maskFilter && (
                <PaginatedListMolecule
                  service={PeopleService}
                  newRow={newRow}
                  updated={updatedItem}
                  perPage={15}
                  scrollPagination={true}
                  idToDelete={toDelete}
                  filter={maskFilter}
                  renderFunc={(item: PeopleModel) => {
                    return (
                      <PeopleRowMolecule
                        person={item}
                        onActionSelected={handleActionSelect}
                      />
                    );
                  }}
                  header={
                    <StyledHeaderContainer sx={{ paddingY: "10px" }}>
                      <Grid container spacing={0}>
                        <StyledGridItem
                          sx={{ paddingLeft: "5px" }}
                          item
                          md={1}
                          xs={12}
                        >
                          <Trans>Verified</Trans>
                        </StyledGridItem>
                        <StyledGridItem
                          sx={{ paddingLeft: "5px" }}
                          item
                          md={1}
                          xs={12}
                        >
                          <Trans>Internal</Trans>
                        </StyledGridItem>
                        <StyledGridItem item md={2} xs={12}>
                          <Trans>Name</Trans>
                          <SortUI
                            name="name"
                            onClick={(info: {
                              name: string | undefined;
                              direction: string | undefined;
                            }) =>
                              processFilter({
                                ...filter,
                                order_field: info.name,
                                order_direction: info.direction,
                              })
                            }
                            order={
                              filter.order_field === "name"
                                ? filter.order_direction
                                : undefined
                            }
                          />
                        </StyledGridItem>
                        <StyledGridItem item md={2} xs={12}>
                          <Trans>Source</Trans>
                        </StyledGridItem>
                        <StyledGridItem item md={2} xs={12}>
                          <Trans>Country</Trans>
                          <SortUI
                            name="country_name"
                            onClick={(info: {
                              name: string | undefined;
                              direction: string | undefined;
                            }) =>
                              processFilter({
                                ...filter,
                                order_field: info.name,
                                order_direction: info.direction,
                              })
                            }
                            order={
                              filter.order_field === "country_name"
                                ? filter.order_direction
                                : undefined
                            }
                          />
                        </StyledGridItem>
                        <StyledGridItem item md={2} xs={12}>
                          <Trans>City</Trans>
                          <SortUI
                            name="city"
                            onClick={(info: {
                              name: string | undefined;
                              direction: string | undefined;
                            }) =>
                              processFilter({
                                ...filter,
                                order_field: info.name,
                                order_direction: info.direction,
                              })
                            }
                            order={
                              filter.order_field === "city"
                                ? filter.order_direction
                                : undefined
                            }
                          />
                        </StyledGridItem>
                        <StyledGridItem item md={2} xs={12}></StyledGridItem>
                      </Grid>
                    </StyledHeaderContainer>
                  }
                />
              )}
            </Grid>
          </Grid>
        </ContainerAtom>
      </LayoutBase>
    </>
  );
};

export default ActivitiesPage;
