import * as React from "react";
import { Box, FormLabel, Typography } from "@mui/material";
import IComponentProps, { YupSchema } from "./interface";
import { FormProvider, useForm } from "react-hook-form";
import { t, Trans } from "@lingui/macro";
import Grid from "@mui/material/Grid";
import RadioGroupMolecule from "@molecules/RadioGroup/Simple";
import SwitchMolecule from "@molecules/Switch";
import TextFieldAtom from "@atoms/TextField";
import { useSelector } from "react-redux";
import HelperErrorMolecule from "@molecules/HelperError";
import { SelectMolecule } from "@molecules/AsyncSelect";
import { yupResolver } from "@hookform/resolvers/yup";

const ClienSupplierRatesForm = ({
  model,
  record,
  errors,
  typeId,
  onChange,
  onIsZero,
  onDirtyChange,
}: IComponentProps) => {
  const [sErrors, setSErrors] = React.useState<any>(errors ? errors : {});
  const currencyList = useSelector(
    (state: any) => state.filters.filters.categories?.rates,
  );
  const ratesTypesList = useSelector(
    (state: any) => state.filters.filters.types?.rates,
  );

  React.useEffect(() => {
    setSErrors(errors ? errors : {});
  }, [errors]);

  const formInstance = useForm<any>({
    mode: "onChange",
    reValidateMode: "onSubmit",
    resolver: yupResolver(YupSchema),
  });

  const {
    watch,
    getValues,
    reset,
    formState: { isDirty },
  } = formInstance;

  const isCalculated = watch("is_calculated");
  const projectValue = Number(watch("value"));
  const extraValue = Number(watch("extra"));
  const daysValue = Number(watch("days"));
  const rateValue = Number(watch("rate"));
  const allowanceQuantityValue = Number(watch("allowance_quantity"));
  const allowanceValue = Number(watch("allowance"));
  const hoursValue = Number(watch("hours"));
  const isHours = watch("time_unit") === "hours";
  const isDays = watch("time_unit") === "days";
  const total = !isCalculated
    ? projectValue + extraValue
    : rateValue * (isDays ? daysValue : hoursValue) +
      (isHours ? allowanceQuantityValue * allowanceValue : 0) +
      extraValue;

  React.useEffect(() => {
    reset({
      category_id: record
        ? record.category_id
        : currencyList[0]
        ? currencyList[0].id
        : "",
      type_id: typeId,
      id: record ? record.id ?? "" : "",
      model_id: model?.id,
      is_calculated: record?.id ? record.info?.is_calculated : false,
      time_qty: record ? (record.info?.time_qty ? record.info.time_qty : 0) : 0,
      time_unit: record
        ? record.info?.time_unit
          ? record.info.time_unit
          : "days"
        : "days",
      allowance_quantity: record
        ? record.info?.allowance_quantity
          ? record.info.allowance_quantity
          : 0
        : 0,
      allowance: record
        ? record.info?.allowance
          ? record.info.allowance
          : 0
        : 0,
      value: record ? (record.value ? record.value : 0) : 0,
      rate: record ? (record.info?.rate ? record.info.rate : 0) : 0,
      hours: record ? (record.info?.hours ? record.info.hours : 0) : 0,
      days: record ? (record.info?.days ? record.info.days : 0) : 0,
      extra: record ? (record.info?.extra ? record.info.extra : 0) : 0,
    });
    if (onChange) {
      onChange(getValues());
    }
  }, [record]);

  React.useEffect(() => {
    const watchAll = formInstance.watch((value, { name, type }) => {
      if (onChange) {
        onChange(getValues());
      }
      // Check if zero
      const isHours = getValues().time_unit === "hours";
      const isDays = getValues().time_unit === "days";
      const daysValue = getValues().days;
      const hoursValue = getValues().hours;
      const allowanceQuantityValue = getValues().allowance_quantity;
      const allowanceValue = getValues().allowance;
      const totalB = !getValues().is_calculated
        ? Number(getValues().value) + Number(getValues().extra)
        : Number(getValues().rate) * (isDays ? daysValue : hoursValue) +
          (isHours ? allowanceQuantityValue * allowanceValue : 0) +
          getValues().extra;
      if ((!totalB || totalB === 0) && onIsZero) {
        onIsZero(true);
      } else if (onIsZero) {
        onIsZero(false);
      }
    });
    return () => watchAll.unsubscribe();
  }, [watch, formInstance, getValues]);

  React.useEffect(() => {
    if (isDirty && onDirtyChange) {
      onDirtyChange(isDirty);
    }
  }, [isDirty]);

  return (
    <Box>
      <form noValidate>
        <FormProvider {...formInstance}>
          <Grid container spacing={1}>
            <Grid item md={6} xs={12}>
              <SelectMolecule
                control={formInstance.control}
                controlName="category_id"
                formError={sErrors["category_id"]}
                storeCollection={"categories.rates"}
                optionValue={"id"}
                variant={"outlined"}
                label={"Currency"}
                optionLabel={"name"}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <SwitchMolecule
                controlName={"is_calculated"}
                control={formInstance.control}
                label={t`Is calculated`}
              />
            </Grid>
          </Grid>
          {isCalculated && (
            <Box mt={1}>
              <Grid container spacing={1}>
                <Grid item md={6} xs={12}>
                  <FormLabel>
                    <Trans>Time unit</Trans>
                  </FormLabel>
                  <RadioGroupMolecule
                    control={formInstance.control}
                    controlName="time_unit"
                    radios={[
                      {
                        label: "hours",
                        value: "hours",
                      },
                      {
                        label: "days",
                        value: "days",
                      },
                    ]}
                  />
                  <HelperErrorMolecule message={sErrors["time_unit"]} />
                </Grid>
                <Grid item md={6} xs={12}></Grid>
              </Grid>
            </Box>
          )}
          <Box mt={1}>
            <Grid container spacing={1}>
              <Grid
                item
                md={6}
                xs={12}
                sx={{ display: !isCalculated ? "none" : "" }}
              >
                <TextFieldAtom
                  InputProps={{ inputProps: { min: 0 } }}
                  type={"number"}
                  controlName={"rate"}
                  variant={"outlined"}
                  formError={sErrors["rate"]}
                  label={
                    typeId === ratesTypesList[0].id ? (
                      <Trans>Client rate</Trans>
                    ) : (
                      <Trans>Supplier rate</Trans>
                    )
                  }
                />
              </Grid>
              <Grid item md={6} xs={12}>
                {isCalculated && isDays && (
                  <Box>
                    <TextFieldAtom
                      type={"number"}
                      InputProps={{
                        inputProps: { min: 0 },
                      }}
                      controlName={"days"}
                      variant={"outlined"}
                      formError={sErrors["days"]}
                      label={<Trans>Days</Trans>}
                    />
                  </Box>
                )}
                {isCalculated && isHours && (
                  <Box>
                    <TextFieldAtom
                      type={"number"}
                      InputProps={{
                        inputProps: { min: 0 },
                      }}
                      controlName={"hours"}
                      variant={"outlined"}
                      formError={sErrors["hours"]}
                      label={<Trans>Hours</Trans>}
                    />
                  </Box>
                )}
              </Grid>
            </Grid>
          </Box>

          <Box mt={1}>
            <Grid container spacing={1}>
              {isCalculated && isHours && (
                <Grid item md={4} xs={12}>
                  <Box>
                    <TextFieldAtom
                      type={"number"}
                      InputProps={{
                        inputProps: { min: 0 },
                      }}
                      controlName={"allowance"}
                      variant={"outlined"}
                      helperText={sErrors["allowance"]}
                      label={
                        typeId === ratesTypesList[0].id ? (
                          <Trans>Client allowance</Trans>
                        ) : (
                          <Trans>Supplier allowance</Trans>
                        )
                      }
                    />
                  </Box>
                </Grid>
              )}
              {isCalculated && isHours && (
                <Grid item md={4} xs={12}>
                  <Box>
                    <TextFieldAtom
                      type={"number"}
                      InputProps={{
                        inputProps: { min: 0 },
                      }}
                      controlName={"allowance_quantity"}
                      variant={"outlined"}
                      helperText={sErrors["allowance_quantity"]}
                      label={<Trans>Allowance quantity</Trans>}
                    />
                  </Box>
                </Grid>
              )}
              {!isCalculated && (
                <Grid item md={6} xs={12}>
                  <TextFieldAtom
                    type={"number"}
                    controlName={"value"}
                    variant={"outlined"}
                    InputProps={{ inputProps: { min: 0 } }}
                    helperText={sErrors["value"]}
                    label={<Trans>Project value</Trans>}
                  />
                </Grid>
              )}
              <Grid item md={isCalculated && isHours ? 4 : 6} xs={12}>
                <TextFieldAtom
                  type={"number"}
                  controlName={"extra"}
                  InputProps={{ inputProps: { min: 0 } }}
                  helperText={sErrors["extra"]}
                  variant={"outlined"}
                  label={<Trans>Extra</Trans>}
                />
              </Grid>
            </Grid>
          </Box>
          <Box mt={1} className="text-right">
            <Typography>
              <b>
                Total:
                {total}
              </b>
            </Typography>
          </Box>
        </FormProvider>
      </form>
    </Box>
  );
};

export default ClienSupplierRatesForm;
