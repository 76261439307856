import { IType } from "@interfaces/IType";
import { InspectionModel } from "@models/InspectionModel";
import { UseFormReturn } from "react-hook-form";

export interface IFormValues {
  _dirty: boolean | undefined; // sys
  job_id: number | undefined;
  type_id: number | null;
  partner_id: number | null;
  status_id: number | null;
  user_id: number | null;
  category_id: number | undefined;
  activity_category_id: number | string;
  user_invoice_id: number | null;
  new_vendor: boolean;
  new_subvendor: boolean;
  new_po: boolean;
  po_name: string | null;
  po_id: number | null;
  vendor_id: number | null;
  subvendor_id: number | null;
  project_label: string | null;
  vendor_name: string | null;
  vendor_email: string | null;
  vendor_country: number | null;
  vendor_division: string | null;
  subvendor_name: string | null;
  subvendor_email: string | null;
  subvendor_country: number | null;
  subvendor_division: string | null;
  country_id: number | null;
  subcontractor_id: number | null;
  labels: Array<any>;
  po: Array<any>;
  street: string;
  different_user_invoice: boolean | null;
  subcontracting: boolean | null;
}

export interface IFormStatusValues {
  status_id: number;
}

export default interface IInspectionCreateOrganism {
  mode?: string;
  onDirtyChange?: (param: boolean) => void;
  inSErrors?: any;
  propagateDirtyStatus?: boolean;
  defaultVendor?: number | null;
  defaultSubvendor?: number | null;
  defaultPo?: number | null;
  jobId?: number | null;
  formListener?: (data: UseFormReturn<IFormValues, any>) => void;
  inspection: InspectionModel;
  onSave?: (param: InspectionModel) => void;
}

export const setDefaultData = (
  inspection: InspectionModel,
  defaultVendor: any,
  inspectionActivitiesCategoriesFilters: Array<IType>,
  defaultPo: any,
  defaultSubvendor: any,
) => {
  console.log(
    "defaultPo",
    defaultPo !== undefined && defaultPo !== null
      ? defaultPo
      : inspection.pos
      ? inspection.pos.id
      : null,
  );
  return {
    job_id: inspection.job_id ? inspection.job_id : undefined,
    type_id: inspection.type ? inspection.type.id : 29,
    activity_category_id: inspection.activity_category_id
      ? inspection.activity_category_id
      : inspectionActivitiesCategoriesFilters?.find(
          (i: any) => i.alias === "inspection",
        )?.id,
    category_id: inspection.category_id ? inspection.category_id : undefined,
    status_id: inspection.status ? inspection.status.id : null,
    project_label: inspection.project_label ? inspection.project_label : null,
    user_id: inspection.user ? inspection.user.id : null,
    partner_id: inspection.partner_id ? inspection.partner_id : null,
    different_user_invoice: inspection.user_invoice ? true : false,
    po_id:
      defaultPo !== undefined && defaultPo !== null
        ? defaultPo
        : inspection.pos
        ? inspection.pos.id
        : null,
    user_invoice_id: inspection.user_invoice
      ? inspection.user_invoice.id
      : null,
    subcontracting: false,
    vendor_id:
      defaultVendor !== undefined && defaultVendor !== null
        ? defaultVendor
        : inspection.vendor
        ? inspection.vendor.id
        : null,
    subvendor_id:
      defaultSubvendor !== undefined && defaultSubvendor !== null
        ? defaultSubvendor
        : inspection.subvendor
        ? inspection.subvendor.id
        : null,
    new_vendor: false,
    new_po: false,
    vendor_name: null,
    vendor_email: null,
    vendor_country: null,
    vendor_division: null,
    country_id: inspection.country ? inspection.country.id : null,
    subcontractor_id: inspection.subcontractor
      ? inspection.subcontractor.id
      : null,
    labels: inspection.labels ? inspection.labels : [],
    po: inspection.po ? inspection.po : [],
    street: inspection.full_address ?? "",
  };
};
