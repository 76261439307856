import * as React from "react";
import { Alert, Box, Button } from "@mui/material";
import IComponentProps from "./interface";
import { UseFormReturn } from "react-hook-form";
import InspectionDetailFormOrganism from "@organisms/InspectionDetailForm";
import { InspectionModel } from "@models/InspectionModel";
import { IFormValues } from "@organisms/InspectionDetailForm/interface";
import { t, Trans } from "@lingui/macro";
import { useMutation } from "react-query";
import InspectionsService from "@services/InspectionsService";
import ButtonAtom from "@atoms/Button";
import ClientsService from "@services/ClientsService";
import { IDS } from "@utils/constants";
import { useAppDispatch } from "@stores/hooks";
import { fetchFilter } from "@stores/reducers/filtersReducer";
import { toast } from "react-toastify";
import POsService from "@services/POsService";

const InspectionDataForm = ({
  mode,
  jobId,
  onBack,
  onNext,
  onInspection,
}: IComponentProps) => {
  const [formInstance, setFormInstance] = React.useState<UseFormReturn<
    IFormValues,
    any
  > | null>(null);
  const [formIsValid, setFormIsValid] = React.useState<boolean>(true);
  const [vendor, setVendor] = React.useState<number | null>(null);
  const [subvendor, setSubvendor] = React.useState<number | null>(null);
  const [po, setPo] = React.useState<number | null>(null);
  const [previousId, setPreviousId] = React.useState<number | null>(null);
  const [errors, setError] = React.useState<boolean>(false);
  const [sErrors, setSErrors] = React.useState<any>([]);
  const dispatch = useAppDispatch();

  const formChangeListener = (form: UseFormReturn<IFormValues, any>) => {
    setFormIsValid(form.formState.isValid);
    setFormInstance(form);
  };

  const { mutate: createMutation, isLoading } = useMutation(
    (formValues: IFormValues) =>
      (!!previousId
        ? InspectionsService.put(formValues, String(previousId))
        : InspectionsService.create(formValues)
      ).then((res: any) => {
        if (!res.hasErrors()) {
          setPreviousId(res.getData().id);
          if (onNext) {
            onNext();
          }
          if (onInspection) {
            onInspection(res.getData());
          }
        } else {
          setError(true);
        }
      }),
  );

  const handleSubmit = () => {
    setError(false);
    if (formInstance) {
      const promises = [];
      const data = formInstance?.getValues();
      const values = { ...data };

      if (data.new_vendor) {
        promises.push(
          ClientsService.create({
            type_id: IDS.ROLES.VENDOR,
            name: data.vendor_name,
            email: data.vendor_email,
            country_id: data.vendor_country,
            division: data.vendor_division,
          }),
        );
      } else {
        values.labels = values.labels.map((label: any) => label.id);
        values.po = values.po.map((label: any) => label.id);
      }

      if (data.new_po) {
        promises.push(
          POsService.create({
            name: data.po_name,
            job_id: jobId,
          }),
        );
      }

      if (data.new_subvendor) {
        promises.push(
          ClientsService.create({
            type_id: IDS.ROLES.VENDOR,
            name: data.subvendor_name,
            email: data.subvendor_email,
            country_id: data.subvendor_country,
            division: data.subvendor_division,
          }),
        );
      }

      Promise.all(promises).then((res: any) => {
        const failed = res.filter((r: any) => r.hasErrors());
        if (failed.length) {
          // setSErrors(res.getErrors()); todo DEVONO ESSERE PRESI DA TUTTE LE CHIAMATE
          toast(t`Unable to update data. Check the fields`, {
            type: "error",
          });
        } else {
          //values.labels = values.labels.map((label: any) => label.id);
          //values.po = values.po.map((label: any) => label.id);
          if (data.new_vendor) {
            setVendor(res[0].getData().id);
            values.vendor_id = res[0].getData().id;
          }
          if (data.new_po) {
            dispatch(fetchFilter("pos"));
            setPo(res[data.new_vendor ? 1 : 0].getData().id);
            values.po_id = res[data.new_vendor ? 1 : 0].getData().id;
          }
          // Calculate the index for new_subvendor response dynamically
          let subvendorIndex = 0;
          if (data.new_vendor) subvendorIndex += 1;
          if (data.new_po) subvendorIndex += 1;
          if (data.new_subvendor) {
            setSubvendor(res[subvendorIndex].getData().id);
            values.subvendor_id = res[subvendorIndex].getData().id;
          }
          // Update list
          if (data.new_subvendor || data.new_vendor) {
            dispatch(fetchFilter("vendors"));
          }
          createMutation({
            ...values,
            ...{ job_id: jobId },
          });
        }
      });
    }
  };

  return (
    <Box>
      <InspectionDetailFormOrganism
        propagateDirtyStatus={mode !== "fast"}
        defaultVendor={vendor}
        defaultSubvendor={subvendor}
        defaultPo={po}
        jobId={jobId}
        inSErrors={sErrors}
        formListener={formChangeListener}
        inspection={new InspectionModel({ job_id: jobId })}
      />
      {errors && (
        <Box mt={2}>
          <Alert severity="error">
            <Trans>An error occurred, please check the fields</Trans>
          </Alert>
        </Box>
      )}
      <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
        {mode !== "fast" && (
          <Button color="inherit" onClick={onBack} sx={{ mr: 1 }}>
            <Trans>Back</Trans>
          </Button>
        )}
        <Box sx={{ flex: "1 1 auto" }} />
        <ButtonAtom
          variant="contained"
          loading={isLoading}
          label={t`Next`}
          onClick={() => handleSubmit()}
          disabled={!formIsValid}
          sx={{ mr: 1 }}
        />
      </Box>
    </Box>
  );
};

export default InspectionDataForm;
